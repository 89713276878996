// Loader.js
import React from 'react';
import './loader.scss';

const Loader = () => {
  return (
    <>

    <div className="loader">
      <div className="bar" style={{ backgroundColor: '#037cff' }}></div>
      <div className="bar" style={{ backgroundColor: 'orange' }}></div>
      <div className="bar" style={{ backgroundColor: '#037cff' }}></div>
      <div className="bar" style={{ backgroundColor: 'orange' }}></div>
    </div>
   
     </>

  );
};

export default Loader;
