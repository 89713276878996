import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "../components/applyform.scss";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS

const ApplyForm = ({ title }) => {
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({ name: "", mobile: "" });
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".pdf, .jpeg, .jpg, .doc, .docx",
    maxSize: 2097152, // 2MB
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formPayload = new FormData();
    formPayload.append("name", formData.name);
    formPayload.append("mobile", formData.mobile);
    formPayload.append("position", title);
    if (file) {
      formPayload.append("file", file);
    }
    setLoading(true);

    try {
      const response = await axios.post(
        "https://email.bluekode.com/send-careers",
        formPayload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Form submitted successfully!");
        // Clear the form data and file state
        setFormData({ name: "", mobile: "" });
        setFile(null);
      }
    } catch (error) {
      toast.error("Error submitting form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      className="apply-form-container"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2>Submit Your Details</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Your Name *</label>
          <input
            className="col-12"
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name"
            required
            disabled={loading}
          />
        </div>
        <div className="form-group">
          <label htmlFor="mobile">Mobile Number *</label>
          <input
            className="col-12"
            type="tel"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            placeholder="Enter your number"
            required
            disabled={loading}
          />
        </div>
        {/* <div className="form-group">
          <label htmlFor="mobile">Looking For *</label>
          <input
            className="col-12"
            type="tel"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            placeholder="Enter your number"
            required
            disabled={loading}
          />
        </div> */}
        <div className="form-group drop-drag">
          <label>Upload Resume here *</label>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} disabled={loading} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>
                Drag & Drop or Click here to upload file is less than 2.0MB
                (PDF, JPEG, or Doc)
              </p>
            )}
          </div>
          {file && <p>{file.name}</p>}
        </div>
        <button type="submit" className="apply-button" disabled={loading}>
          {loading ? "Submitting..." : "Apply now"}
        </button>
      </form>
      <ToastContainer />
    </motion.div>
  );
};

export default ApplyForm;
