import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./navbar.scss";
import logo from "../images/logo.png";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faChartLine,
  faUserShield,
  faBriefcase,
  faChevronDown,
  faHandHolding,
  faOilWell,
} from "@fortawesome/free-solid-svg-icons"; // New icons
import { FaYoast } from "react-icons/fa";
import { useMediaQuery } from "@mui/material";
import posicon from "../images/icons/posicon.png";
import rmsicon from "../images/icons/rmsicon.png";

const Navbar = () => {
  const [scroll, setScroll] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
    return () => window.removeEventListener("scroll", () => {});
  }, []);

  return (
    <div className="navbar-good">
      <header className={`headerMain ${scroll ? "sticky" : ""}`}>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <NavLink className="navbar-brand" to="/">
              <img
                src={logo}
                loading="lazy"
                alt="Bluekode Solutions"
                style={{
                  width: window.innerWidth > 780 ? "120px" : "100px",
                  height: window.innerWidth > 780 ? "" : "50px",
                }}
              />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/" activeClassName="active">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/about"
                    activeClassName="active"
                  >
                    About
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <div
                    className="nav-link"
                    style={{ display: "flex", color: "black" }}
                  >
                    Products{" "}
                    <FontAwesomeIcon
                      className="ps-2"
                      style={{ marginTop: "5px" }}
                      icon={faChevronDown}
                    />
                  </div>
                  <div className="dropdown-menu">
                    <NavLink className="dropdown-item" to="/product/pos">
                      <img
                        src={posicon}
                        className="dropdown-icon"
                        style={{ width: "40px" }}
                        alt="posicon"
                      />
                      {isMobile ? (
                        <span style={{ marginRight: "80px" }}>POS</span>
                      ) : (
                        <span style={{ marginRight: "110px" }}>
                          Point of Sales (POS)
                        </span>
                      )}
                    </NavLink>
                    <NavLink className="dropdown-item" to="/product/rms">
                      <img
                        src={rmsicon}
                        className="dropdown-icon"
                        style={{ width: "40px" }}
                        alt="rms icon"
                      />
                      {isMobile ? (
                        <span style={{ marginRight: "80px" }}>RMS</span>
                      ) : (
                        <span style={{ marginLeft: "5px" }}>
                          {" "}
                          Restaurant Management System (RMS)
                        </span>
                      )}
                    </NavLink>
                    <NavLink className="dropdown-item" to="/product/crm">
                      <FontAwesomeIcon
                        icon={faUserShield}
                        className="dropdown-icon"
                        style={{ color: "green", marginRight: "-5px" }}
                      />
                      {isMobile ? (
                        <span style={{ marginRight: "80px" }}>CRM</span>
                      ) : (
                        <span>customer Relationship Management (CRM)</span>
                      )}
                    </NavLink>
                    <NavLink className="dropdown-item" to="/product/erp">
                      <FontAwesomeIcon
                        icon={faBriefcase}
                        className="dropdown-icon"
                        style={{ color: "skyblue" }}
                      />
                      {isMobile ? (
                        <span style={{ marginRight: "80px" }}>ERP</span>
                      ) : (
                        <span style={{ marginRight: "10px" }}>
                          enterprises resource planning (ERP)
                        </span>
                      )}
                    </NavLink>
                    <NavLink className="dropdown-item" to="/product/scm">
                      <FontAwesomeIcon
                        icon={faHandHolding}
                        className="dropdown-icon"
                        style={{ color: "orange" }}
                      />
                      {isMobile ? (
                        <span style={{ marginRight: "80px" }}>SCM</span>
                      ) : (
                        <span style={{ marginRight: "20px" }}>
                          Supply Chain Management (SCM)
                        </span>
                      )}
                    </NavLink>
                    <NavLink className="dropdown-item" to="/product/ims">
                      <FontAwesomeIcon
                        icon={faOilWell}
                        className="dropdown-icon"
                        style={{ color: "" }}
                      />
                      <span style={{ marginRight: "180px" }}>Inventory</span>
                    </NavLink>
                  </div>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/service"
                    activeClassName="active"
                  >
                    Services
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/careers"
                    activeClassName="active"
                  >
                    Careers
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/contact"
                    activeClassName="active"
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
