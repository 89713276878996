import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import "../components/footer.scss";
import { useMediaQuery } from "@mui/material";

const FooterContainer = styled(motion.footer)`
  color: white;
  padding: 20px;
  text-align: center;
  font-family: Rubik, sans-serif;
  font-size: 18px;
  font-weight: 500;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex: 1;
  margin: 20px;
  min-width: 200px;
`;

const Title = styled.h4`
  margin-bottom: 10px;
  text-align: left;
`;

const Text = styled.p`
  line-height: 1.6;
  text-align: justify;
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  a {
    margin: 0 10px;
    color: white;
    text-decoration: none;
    font-size: 24px;
  }
`;

const Footer = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <>
      <FooterContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <FooterContent>
          <Column className="bks-content">
            <Title className="text-primary">Bluekode Solutions</Title>
            <Text style={{ fontWeight: "400" }}>
              Bluekode Solutions is one of the leading IT services companies
              that embarked on its journey in 2005. With a group of highly
              skilled software enthusiasts, we always strive to provide the most
              powerful & innovative IT solutions to businesses of all sizes &
              shapes, across the globe.
            </Text>
            <div className="text-left pt-5 pb-2 d-flex justify-content-left">
              <h6 className="">Follow us on</h6>
            </div>

            <SocialIcons style={{ display: "flex", justifyContent: "left" }}>
              <a
                href="https://www.facebook.com/Bluekodesolutions"
                target="_blank"
                rel="noopener noreferrer"
                style={{ background: "white", padding: "4px 0px 4px 8px" }}
              >
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20 0C8.9543 0 0 8.9543 0 20C0 30.4891 7.30824 39.1055 16.875 40V25.9221H11.8408V20H16.875V15.5794C16.875 10.5747 19.7804 8.25 24.3154 8.25C26.4506 8.25 28.2361 8.40625 28.6858 8.46875V13.5H26.2553C24.0461 13.5 23.125 14.7231 23.125 16.625V20H28.5032L27.6354 25.9221H23.125V40C32.6918 39.1055 40 30.4891 40 20C40 8.9543 31.0457 0 20 0Z"
                    fill="blue"
                  />
                </svg>
              </a>

              <a
                href="https://www.instagram.com/bluekodesolutions/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ background: "white", padding: "4px 0px 4px 8px" }}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <linearGradient
                      id="grad1"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop
                        offset="10%"
                        style={{ stopColor: "#962fbf", stopOpacity: "1" }}
                      />
                      <stop
                        offset="30%"
                        style={{ stopColor: "#d62976", stopOpacity: "1" }}
                      />
                      <stop
                        offset="50%"
                        style={{ stopColor: "#fa7e1e", stopOpacity: "1" }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: " #feda75", stopOpacity: "1" }}
                      />
                    </linearGradient>
                  </defs>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.68609 2.25488C7.71338 2.25488 5.82142 3.03835 4.42623 4.433C3.03104 5.82765 2.24685 7.71931 2.24609 9.69203V29.532C2.24609 31.5052 3.02995 33.3976 4.42522 34.7929C5.82049 36.1882 7.71288 36.972 9.68609 36.972H29.5261C31.4988 36.9713 33.3905 36.1871 34.7851 34.7919C36.1798 33.3967 36.9632 31.5047 36.9632 29.532V9.69203C36.9625 7.71981 36.1787 5.82858 34.7841 4.43401C33.3895 3.03944 31.4983 2.25564 29.5261 2.25488H9.68609ZM31.6661 9.70345C31.6661 10.2718 31.4403 10.8168 31.0385 11.2187C30.6366 11.6205 30.0916 11.8463 29.5232 11.8463C28.9549 11.8463 28.4099 11.6205 28.008 11.2187C27.6061 10.8168 27.3804 10.2718 27.3804 9.70345C27.3804 9.13513 27.6061 8.59009 28.008 8.18823C28.4099 7.78636 28.9549 7.5606 29.5232 7.5606C30.0916 7.5606 30.6366 7.78636 31.0385 8.18823C31.4403 8.59009 31.6661 9.13513 31.6661 9.70345ZM19.6089 13.6692C18.0328 13.6692 16.5212 14.2953 15.4067 15.4098C14.2922 16.5243 13.6661 18.0359 13.6661 19.612C13.6661 21.1882 14.2922 22.6998 15.4067 23.8143C16.5212 24.9288 18.0328 25.5549 19.6089 25.5549C21.1851 25.5549 22.6967 24.9288 23.8112 23.8143C24.9257 22.6998 25.5518 21.1882 25.5518 19.612C25.5518 18.0359 24.9257 16.5243 23.8112 15.4098C22.6967 14.2953 21.1851 13.6692 19.6089 13.6692ZM10.8061 19.612C10.8061 17.2781 11.7332 15.0398 13.3836 13.3895C15.0339 11.7392 17.2722 10.812 19.6061 10.812C21.94 10.812 24.1783 11.7392 25.8286 13.3895C27.479 15.0398 28.4061 17.2781 28.4061 19.612C28.4061 21.9459 27.479 24.1842 25.8286 25.8346C24.1783 27.4849 21.94 28.412 19.6061 28.412C17.2722 28.412 15.0339 27.4849 13.3836 25.8346C11.7332 24.1842 10.8061 21.9459 10.8061 19.612Z"
                    fill="url(#grad1)"
                  />
                </svg>
              </a>
              <a
                href="https://in.linkedin.com/company/bluekode-solutions"
                target="_blank"
                rel="noopener noreferrer"
                style={{ background: "white", padding: "4px 0px 4px 8px" }}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 6.22672C4 5.00029 5.052 4.00635 6.35 4.00635H33.65C34.948 4.00635 36 5.00029 36 6.22672V32.786C36 34.0124 34.948 35.0063 33.65 35.0063H6.35C5.052 35.0063 4 34.0124 4 32.786V6.22672ZM13.886 29.9572V15.9588H9.084V29.9572H13.886ZM11.486 14.0465C13.16 14.0465 14.202 12.9731 14.202 11.6285C14.172 10.2548 13.162 9.21047 11.518 9.21047C9.874 9.21047 8.8 10.2567 8.8 11.6285C8.8 12.9731 9.842 14.0465 11.454 14.0465H11.486ZM21.302 29.9572V22.1394C21.302 21.7209 21.334 21.3024 21.462 21.004C21.808 20.169 22.598 19.3029 23.926 19.3029C25.664 19.3029 26.358 20.5855 26.358 22.4688V29.9572H31.16V21.9282C31.16 17.627 28.792 15.6275 25.632 15.6275C23.084 15.6275 21.942 16.9837 21.302 17.9389V17.9873H21.27L21.302 17.9389V15.9588H16.502C16.562 17.2724 16.502 29.9572 16.502 29.9572H21.302Z"
                    fill="darkblue"
                  />
                </svg>
              </a>
            </SocialIcons>
            <div
              className="container"
              style={{ width: "100%", padding: "40px 5px" }}
            >
              <iframe
                className="rounded"
                width="100%"
                height="200"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Bluekode%20Solution,3rd%20Floor,%20No.33,%20TV%20Swamy%20Rd(East),%20R.S.Puram,%20Coimbatore%20-%20641002.+(Bluekode%20solution)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a href="https://www.gps.ie/">gps tracker sport</a>
              </iframe>
            </div>
          </Column>

          <div className="col-lg-2"></div>
          <Column>
            <Text style={{ color: "#D3D3D3" }}>
              <svg
                width="45"
                height="45"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.00634766"
                  width="52"
                  height="52"
                  rx="26"
                  fill="#F17B21"
                />
                <path
                  d="M34 18.0063H18C16.9 18.0063 16.01 18.9063 16.01 20.0063L16 32.0063C16 33.1063 16.9 34.0063 18 34.0063H34C35.1 34.0063 36 33.1063 36 32.0063V20.0063C36 18.9063 35.1 18.0063 34 18.0063ZM33.6 22.2563L26.53 26.6763C26.21 26.8763 25.79 26.8763 25.47 26.6763L18.4 22.2563C18.2997 22.2001 18.2119 22.124 18.1419 22.0328C18.0719 21.9416 18.0211 21.8371 17.9926 21.7257C17.9641 21.6143 17.9585 21.4983 17.9762 21.3847C17.9938 21.271 18.0343 21.1622 18.0953 21.0646C18.1562 20.9671 18.2363 20.883 18.3307 20.8173C18.4251 20.7517 18.5319 20.7059 18.6445 20.6826C18.7571 20.6594 18.8733 20.6593 18.986 20.6823C19.0986 20.7053 19.2055 20.7509 19.3 20.8163L26 25.0063L32.7 20.8163C32.7945 20.7509 32.9014 20.7053 33.014 20.6823C33.1267 20.6593 33.2429 20.6594 33.3555 20.6826C33.4681 20.7059 33.5749 20.7517 33.6693 20.8173C33.7637 20.883 33.8438 20.9671 33.9047 21.0646C33.9657 21.1622 34.0062 21.271 34.0238 21.3847C34.0415 21.4983 34.0359 21.6143 34.0074 21.7257C33.9789 21.8371 33.9281 21.9416 33.8581 22.0328C33.7881 22.124 33.7003 22.2001 33.6 22.2563Z"
                  fill="white"
                />
              </svg>
              Mail to us{" "}
              <div className="info">
                <a
                  href="mailto:info@bluekode.com"
                  className="text-decoration-none text-white"
                >
                  info@bluekode.com
                </a>
              </div>
              <br />
              <br />
              <svg
                width="45"
                height="45"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.00634766"
                  width="52"
                  height="52"
                  rx="26"
                  fill="#F17B21"
                />
                <path
                  d="M33.5075 28.4963C34.1618 27.2683 34.5027 25.8978 34.5 24.5063C34.5 19.8118 30.6945 16.0063 26 16.0063C21.3055 16.0063 17.5 19.8118 17.5 24.5063C17.4965 26.5115 18.2053 28.4527 19.5 29.9838L19.51 29.9963L19.519 30.0063H19.5L24.544 35.3613C24.731 35.5598 24.9566 35.718 25.2069 35.8261C25.4573 35.9342 25.7271 35.9899 25.9998 35.9899C26.2724 35.9899 26.5423 35.9342 26.7926 35.8261C27.0429 35.718 27.2685 35.5598 27.4555 35.3613L32.5 30.0063H32.481L32.489 29.9968L32.49 29.9958C32.526 29.9528 32.5618 29.9095 32.5975 29.8658C32.9444 29.4397 33.2492 28.9814 33.5075 28.4963ZM26.0025 27.7558C25.2069 27.7558 24.4438 27.4398 23.8812 26.8772C23.3186 26.3146 23.0025 25.5515 23.0025 24.7558C23.0025 23.9602 23.3186 23.1971 23.8812 22.6345C24.4438 22.0719 25.2069 21.7558 26.0025 21.7558C26.7982 21.7558 27.5612 22.0719 28.1238 22.6345C28.6864 23.1971 29.0025 23.9602 29.0025 24.7558C29.0025 25.5515 28.6864 26.3146 28.1238 26.8772C27.5612 27.4398 26.7982 27.7558 26.0025 27.7558Z"
                  fill="white"
                />
              </svg>
              Headquarters
              <br />
              <div className="info">
                {" "}
                PAZHAMUDIR SQUARES, 3rd Floor,
                <br /> No.33, TV Swamy Rd(East), R.S.Puram,
                <br /> Coimbatore - 641002.
              </div>
              <br />
              <br />
              <svg
                width="45"
                height="45"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.00634766"
                  width="52"
                  height="52"
                  rx="26"
                  fill="#F17B21"
                />
                <path
                  d="M33.2291 29.2664L30.6891 28.9764C30.3904 28.9413 30.0877 28.9743 29.8036 29.0731C29.5196 29.1718 29.2616 29.3336 29.0491 29.5464L27.2091 31.3864C24.3703 29.9426 22.0628 27.6352 20.6191 24.7964L22.4691 22.9464C22.8991 22.5164 23.1091 21.9164 23.0391 21.3064L22.7491 18.7864C22.6924 18.2985 22.4583 17.8486 22.0913 17.5222C21.7243 17.1958 21.2502 17.0158 20.7591 17.0164H19.0291C17.8991 17.0164 16.9591 17.9564 17.0291 19.0864C17.5591 27.6264 24.3891 34.4464 32.9191 34.9764C34.0491 35.0464 34.9891 34.1064 34.9891 32.9764V31.2464C34.9991 30.2364 34.2391 29.3864 33.2291 29.2664Z"
                  fill="white"
                />
              </svg>
              Call Support Team
              <div className="info">
                <div style={{ letterSpacing: "2px" }}>
                  <span
                    className="label"
                    style={{ marginRight: "32px", letterSpacing: "normal" }}
                  >
                    Sales{" "}
                  </span>{" "}
                  <a
                    href="tel:+919443185856"
                    className="text-decoration-none text-white"
                  >
                    +91 94431 85856
                  </a>
                </div>
                <div style={{ letterSpacing: "2px" }}>
                  <span
                    className="label"
                    style={{ marginRight: "10px", letterSpacing: "normal" }}
                  >
                    Support{" "}
                  </span>{" "}
                  +91 96555 93401
                </div>
                <div style={{ letterSpacing: "2px" }}>
                  <span
                    className="label"
                    style={{ marginRight: "26px", letterSpacing: "normal" }}
                  >
                    Office{" "}
                  </span>{" "}
                  +91 0422 - 2554001
                </div>
              </div>
              <br />
              <br />
              <div
                className="fw-bold text-light"
                style={{ paddingLeft: isMobile ? "" : "54px" }}
              >
                {" "}
                Available at
              </div>
              <br />
              <div style={{ paddingLeft: isMobile ? "" : "54px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    gap: isMobile ? "0px" : "4px",
                  }}
                >
                  {" "}
                  <p> Monday – Friday : </p>
                  <p style={{ paddingLeft: isMobile ? "85px" : "" }}>
                    {" "}
                    10:00 AM to 06:00 PM
                  </p>
                </div>
                <p
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    gap: "5px",
                  }}
                >
                  {" "}
                  Saturday :{" "}
                  <p style={{ paddingLeft: isMobile ? "85px" : "" }}>
                    {" "}
                    10:00 AM to 01:00 PM{" "}
                  </p>
                </p>
              </div>
            </Text>
          </Column>
        </FooterContent>
      </FooterContainer>
      <Text className="d-flex justify-content-center pb-0 mb-0">
        <p>© 2024. bluekode.com All rights reserved</p>
      </Text>
    </>
  );
};

export default Footer;
