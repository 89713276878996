import React from "react";
import Navbar from "../components/Navbar";
import "../css/careers.scss";
import team from "../images/Team-img.png";
import lead1 from "../images/icons/ladder 1.png";
import lead2 from "../images/icons/network 1.png";
import lead3 from "../images/icons/sunbed 1.png";
import Oppertunity from "../components/Oppertunity";
import { Fade } from "@mui/material";
import { useInView } from "react-intersection-observer";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export const Career = () => {
  const { ref: benefitsRef, inView: benefitsInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div>
      <Helmet>
        <title>
          IT Jobs in Coimbatore | Latest Job Vacancies at Bluekode Solutions{" "}
        </title>
        <meta
          name="description"
          content="Looking for IT Jobs in Coimbatore? Bluekode Solutions offers a variety of IT job opportunities in Coimbatore for both freshers and experienced professionals. Whether you're seeking technical or non-technical roles, explore our latest job openings and take the next step in your IT career with us."
        ></meta>
        <meta
          name="keywords"
          content="front-end development jobs in Coimbatore, back-end development jobs in Coimbatore, Full-Stack development vacancies in Coimbatore, Software testing, Graphic design, Digital marketing, and POS software"
        ></meta>
      </Helmet>
      <Navbar />
      <div className="carrer-page">
        <Fade in={true} timeout={1000}>
          <header className="header">
            <h1 style={{ fontWeight: 700 }}>
              Become a Value Pillar of <br /> Our Bluekode Family
            </h1>
            <a href="#careers" className="careers-link">
              Careers opportunity
            </a>
            <p>
              We believe in fostering a culture where talent thrives, innovation
              flourishes, and individuals are empowered to make a meaningful
              impact. If you're passionate about driving change, shaping the
              future, and being part of a dynamic team, you've come to the right
              place.
            </p>
          </header>
        </Fade>

        <Fade in={true} timeout={1500}>
          <div className="image-section">
            <img src={team} loading="lazy" alt="Team" />
          </div>
        </Fade>

        <section className="benefits" ref={benefitsRef}>
          <h2>Employee Benefits</h2>
          <p>
            Our comprehensive benefits package is designed to support your
            well-being, enhance your work-life balance, and empower you to excel
            in your role. Here's a glimpse of what you can expect:
          </p>
          <div className="benefits-list">
            <Fade
              direction="right"
              in={benefitsInView}
              style={{ transitionDelay: "0.5s" }}
            >
              <div className="benefit-item">
                <img
                  src={lead1}
                  loading="lazy"
                  alt="Career Growth"
                  className="img-fluid"
                />
                <h3>Career Growth and Tech Classes</h3>
              </div>
            </Fade>
            <Fade
              direction="right"
              in={benefitsInView}
              style={{ transitionDelay: "1s" }}
            >
              <div className="benefit-item benifititem2">
                <img src={lead2} loading="lazy" alt="Employee Referral" />
                <h3>Employee Referral Program</h3>
              </div>
            </Fade>
            <Fade
              direction="right"
              in={benefitsInView}
              style={{ transitionDelay: "1.5s" }}
            >
              <div className="benefit-item benifititem3">
                <img src={lead3} alt="Paid Leaves" />
                <h3>Paid Leaves and Permissions</h3>
              </div>
            </Fade>
          </div>
        </section>

        <Fade in={true} timeout={2500}>
          <footer className="footer" style={{ background: "white" }}>
            <h2>Bringing together the best minds of people</h2>
            <a href="#careers" className="careers-link mt-2">
              Our Current Openings
            </a>
          </footer>
        </Fade>
        <Oppertunity />
      </div>

      <Footer />
    </div>
  );
};
